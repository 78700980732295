import React from 'react';
import { Redirect , withRouter } from 'react-router';

import { Row, Col, Form, Icon, Input, Button } from 'antd';
import './style.css'

class AMAuthInner extends React.Component {

    constructor( props ) {
        super( props );        
        this.handleLogin=this.handleLogin.bind(this);
        this.handleLogin=this.handleLogin.bind(this);
        this.setEmail=this.setEmail.bind(this);
        this.setPass=this.setPass.bind(this);
        
        
        this.state = {
                email: '',
                password: '',
                message: '',
                redirectToHome: false,
        }
        
    }
    
    handleLogin(){       
      
        fetch( process.env.REACT_APP_API_ENDPOINT + '/auth' ,
                {
                   credentials: "include",
                   headers: {
                      'Accept': 'application/json',
                      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'   
                   },
                   method: "POST",
                   body: 'email=' + this.state.email + '&password=' + this.state.password
                })
                .then(data=> data.json())
                .then(data => {
                    
                    if(data.error===false){
                       
                        this.setState({
                            ...this.state, redirectToHome: true
                        }) 
                        
                    }else{
                       this.setState({
                           ...this.state, message: data.message
                       }) 
                    }
                })
        
    }
    
    setEmail(e){
        this.setState({
            ...this.state, email: e.target.value
        })
    }
    
    setPass(e){
        this.setState({
            ...this.state, password: e.target.value
        })
    }
    
    
    
    render() {
        
        if(this.state.redirectToHome){
            return <Redirect to='/home' />
        }
        
        
        const { getFieldDecorator } = this.props.form;
        return (
          
          <Row type="flex" justify="center">
          
          <Col justify="center" xs={{ span: 24}} lg={{ span: 12}} >
                
          <h1 className="center">Welcome to Virtual Marketplace</h1> 
          
          <Form onSubmit={this.handleSubmit} className="login-form loginform">
            <Form.Item>
              {getFieldDecorator('userName', {
                rules: [{ required: true, message: 'Please input your username!' }],
              })(
                <Input onChange={ this.setEmail } prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please input your Password!' }],
              })(
                <Input onChange={ this.setPass } prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
              )}
            </Form.Item>
            
            <Form.Item>
            
             {
             /*
              {getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: true,
              })(
                <Checkbox>Remember me</Checkbox>
              )}
              */
              }
              
              <Button onClick={this.handleLogin} type="primary" htmlType="submit" className="login-form-button">
                Log in
              </Button>  
            </Form.Item>
            
            <Form.Item>
              <p>{this.state.message}</p>
            </Form.Item>
            
          </Form>
          
          </Col>
          
          </Row>
              
        );
     }
}

const AMAuth = Form.create({ name: 'normal_login' })(AMAuthInner);              
              
export default withRouter((AMAuth))