import React, { Component } from 'react';
import { Row, Col } from 'antd';
import VMTable from '../VMTable'
import { withRouter } from 'react-router';

class VMHome extends Component {

    render() {
        return (
                <div>
                   <Row type="flex">
                      <Col xs={24}> <VMTable /> </Col>
                   </Row>
                </div>
        )
    }
}

export default withRouter(VMHome)