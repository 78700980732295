const initialState = { 
    
    products: [
               {    
                   prodKey : "",    
                   prodSupplier : "", 
                   prodId : "",
                   prodAltKey :"",
                   prodEAN : "",
                   prodInsIns: "",
                   prodLastUpdIns : "",
                   prodUserIns : "",
                   prodUserLastUpd: "",
                   prodVersion : "",
                   prodFamilyKey: "",
                           
                   family : {
                       famKey : "",
                       famDesc: ""
                   },
                   
                   status : {
                       staKey:"",
                       staDesc:"",
                       staShortDesc:""
                   },
                   
                   userIns : {
                       uid: "",
                       uName : "",
                       uNick : "",
                       uEmail: ""
                   },
                   userLastUpd : {
                       uid: "",
                       uName : "",
                       uNick : "",
                       uEmail: ""
                   }
              }
            ],
    loadComplete : false
}

const productsRed = (state = initialState, action) => {
    
  switch (action.type) {
    case 'LOAD_PROD':
        console.log("products reducers - LOAD PRODUCTS")
        console.log(action.data)  
        
        let newState = {
            ...state,
            products : action.data,
            loadComplete : true
        }
        return newState;
           
    default:
        console.log("activities reducers - default")  
        return state
  }
}

export default productsRed