import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import './style.css' 
import { withRouter } from 'react-router';
import {loadProducts} from '../../actions/action.js'
import { connect } from 'react-redux'
import { Tag } from 'antd';
import {colorsTag} from  '../../common/color.js'
import {userTag}  from  '../../common/color.js'
import {statusTag}  from  '../../common/color.js'
import {Popover, Icon} from 'antd'

/*Insert global state from reducers into props*/
const mapStateToProps = ( state, ownProps ) => {
       return {
          initCompleted : state.initRed.initCompleted,
          initdata : state.initRed.initdata,
          productsRed:  state.productsRed
       }
}

class VMTable extends Component {

    /*https://react-table.js.org/#/story/readme */
    /*https://github.com/react-tools/react-table*/
        
    constructor( props ) {
        super( props );
        this.onRowClick = this.onRowClick.bind(this);
        this.showMessageNotAval = this.showMessageNotAval.bind(this)
        
        // MAP PROPS TO LOCAL STATE
        this.state = {
            products : this.props.productsRed.products,
            loadComplete : this.props.productsRed.loadComplete
        }
    }

    componentDidMount() {
        console.log("VMTable - Component did mount...")
        fetch( process.env.REACT_APP_API_ENDPOINT + '/prod', {
            credentials: 'include'
        })
          .then(response => response.json())
          .then(data => data.data)
          .then(data => {
              this.props.dispatch(loadProducts(data))
          })
    }
    
    componentDidUpdate(prevProps){
        
        if (this.props !== prevProps) {
            this.setState({
                products : this.props.productsRed.products,
                loadComplete : this.props.productsRed.loadComplete
            })
          }
    }
    
    
    
    onRowClick(state, rowInfo, column, instance) {
        return {
            onClick: e => {
                this.props.history.push('/detail/' + rowInfo.original.prodKey);
            }
        }
    }

    
    showMessageNotAval(e){
        e.stopPropagation();
        alert("La funzione di aggiornamento manuale non ancora disponibile")
    }
    
    render() {
        
        console.log("Show table for products")
        if(this.state.loadComplete===false)
        {
            return (<div><p>Loading...</p></div>)
        }
        return (
            <div>
                <ReactTable
                    data={this.state.products}
                    filterable
                    defaultFilterMethod={( filter, row ) =>
                        String( row[filter.id] ) === filter.value}
                    columns={[
                        {
                            Header: "Key",
                            accessor: "prodKey",
                            width: 52,
                            
                            filterMethod: ( filter, row ) => {
                                    return row[filter.id]==filter.value
                            }
                        },
                        {
                            Header: "Cod.For.",
                            accessor: "prodSupplier",
                            width: 52,
                            filterMethod: ( filter, row ) => {
                               if(row[filter.id]===null) {
                                   return false
                               }
                               else {
                                   return ( row[filter.id].trim().toUpperCase().startsWith(filter.value.toUpperCase() ) &&
                                            row[filter.id].trim().toUpperCase().endsWith(filter.value.toUpperCase())
                                           )
                               }
                            }
                        },
                        {
                            Header: "Precodice",
                            accessor: "prodPrecode",
                            width: 52,
                            filterMethod: ( filter, row ) => {
                                if(row[filter.id]===null) {
                                    return false
                                }
                                else {
                                    return ( row[filter.id].trim().toUpperCase().startsWith(filter.value.toUpperCase() ) &&
                                             row[filter.id].trim().toUpperCase().endsWith(filter.value.toUpperCase())
                                            )
                                }
                             }
                        },
                        {
                            Header: "Id",
                            accessor: "prodId",
                            width: 130,
                            filterMethod: ( filter, row ) => {
                                if(row[filter.id]===null) {
                                    return false
                                }
                                else {
                                    return (row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()) != -1)
                                }
                            }
                        },
                        {
                            Header: "Id Alternativo",
                            accessor: "prodAltId",
                            width: 130,
                            filterMethod: ( filter, row ) => {
                               
                               if(row[filter.id]===null) {
                                   return false
                               }
                               else {
                                   return (row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()) != -1)
                               }
                               
                            }
                        },
                        
                        
                        {
                            Header: "Ext.Id Type",
                            accessor: "prodExternalIdType",
                            width: 58,
                            filterMethod: ( filter, row ) =>
                                row[filter.id].startsWith( filter.value ) &&
                                row[filter.id].endsWith( filter.value )
                        },
                        {
                            Header: "Ext.Id",
                            accessor: "prodExternalId",
                            width: 130,
                            filterMethod: ( filter, row ) => {
                                if(row[filter.id]===null) {
                                    return false
                                }
                                else {
                                    return (row[filter.id].toUpperCase().indexOf(filter.value.toUpperCase()) != -1)
                                }
                            }
                        },
                        {
                            Header: "Fam.",
                            accessor: "family.famDesc",
                            width: 220,
                            filterMethod: ( filter, row ) =>
                                row[filter.id].startsWith( filter.value ) &&
                                row[filter.id].endsWith( filter.value )
                        },
                        /*
                        {
                            Header: "Ins. Time",
                            accessor: "prodInsIns",
                            width: 200,
                            filterMethod: ( filter, row ) =>
                                row[filter.id].startsWith( filter.value ) &&
                                row[filter.id].endsWith( filter.value )
                        },
                        {
                            Header: "Upd. Time",
                            accessor: "prodLastUpdIns",
                            width: 200,
                            filterMethod: ( filter, row ) =>
                                row[filter.id].startsWith( filter.value ) &&
                                row[filter.id].endsWith( filter.value )
                        },
                        */
                        {
                            Header: "Us. Ins.",
                            accessor: "userIns.uNick",
                            width: 64,
                            
                            Cell: row => {
                                if(row.original.userIns!==undefined){
                                  var c = userTag[row.original.userIns.uid];
                                     return ( <Tag title={row.original.userIns.uName} color={c}> {row.original.userIns.uNick} </Tag> )
                                  }
                            }, 
                            
                            filterMethod: ( filter, row ) =>
                                row[filter.id].startsWith( filter.value ) &&
                                row[filter.id].endsWith( filter.value )
                        },
                        {
                            Header: "Us. Upd.",
                            accessor: "userLastUpd.uNick",
                            width: 64,
                            Cell: row => {
                                if(row.original.userLastUpd!==undefined){
                                  var c = userTag[row.original.userLastUpd.uid];
                                     return ( <Tag title={row.original.userLastUpd.uName} color={c}> {row.original.userLastUpd.uNick} </Tag> )
                                  }
                            }, 
                            
                            filterMethod: ( filter, row ) =>
                                row[filter.id].startsWith( filter.value ) &&
                                row[filter.id].endsWith( filter.value )
                        },    
                        {
                            Header: "Vrsn",
                            accessor: "prodVersion",
                            width: 48,
                            filterMethod: ( filter, row ) =>
                                row[filter.id] == filter.value
                        },
                        {
                            Header: "Stato",
                            accessor: "status.staShortDesc",
                            width: 52,
                            
                            Cell: row => {
                                if(row.original.status.staShortDesc!==undefined){
                                  var c = statusTag[row.original.status.staKey];
                                     return ( <Tag title={row.original.status.staDesc} color={c}> {row.original.status.staShortDesc} </Tag> )
                                  }
                            }, 
                            
                            filterMethod: ( filter, row ) =>
                                row[filter.id].startsWith( filter.value ) &&
                                row[filter.id].endsWith( filter.value )
                        },
                        {
                            Header: "Disp.",
                            accessor: "prodAvailability",
                            width: 60,
                            filterMethod: ( filter, row ) =>
                                row[filter.id]==filter.value
                        },
                        {
                            Header: "Ult. Agg. Disp.",
                            accessor: "prodAvailLastCheck",
                            width: 160,
                            filterMethod: ( filter, row ) =>
                                row[filter.id].startsWith( filter.value ) &&
                                row[filter.id].endsWith( filter.value )
                        },
                        {
                            Header: "Dettagli",
                            accessor: "prodInsIns",
                            width: 50,
                            Cell: row => {
                                     const content = (
                                        <div>
                                           <table>
                                              <tbody>
                                                 <tr>
                                                   <td style={{'width':'15em'}}>Data Inserimento:</td>
                                                   <td><b>{row.original.prodInsIns}</b></td>
                                                 </tr>
                                                 <tr>
                                                   <td style={{'width':'15em'}}>Utente Inserimento:</td>
                                                   <td><b>{row.original.userIns.uName}</b></td>
                                                 </tr>
                                                 <tr>
                                                    <td style={{'width':'15em'}}>Data Aggiornamento:</td>
                                                    <td><b>{row.original.prodLastUpdIns}</b></td>
                                                 </tr>
                                                 <tr>
                                                   <td style={{'width':'15em'}}>Utente Ultimo Aggiornamento:</td>
                                                   <td><b>{row.original.userLastUpd.uName}</b></td>
                                                 </tr>
                                              </tbody>
                                           </table>
                                        </div>
                                      );
                                     
                                     return ( 
                                             <Popover content={content} title="Altri dettagli">
                                                <Icon type="info-circle" />
                                             </Popover>
                                     )
                            },
                            filterable: false,
                            sortable: false,
                            
                        },
                        {
                            Header: "Agg. Qnt.",
                            width: 80,
                            Cell: row => {                                 
                                     return ( <Icon onClick={this.showMessageNotAval} type="reload" />)
                            },
                            filterable: false,
                            sortable: false,
                        }
                    ]}
                    defaultPageSize={200}
                    className="-striped -highlight"
                    getTrProps={this.onRowClick}
                />
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps)(VMTable))