import React, { Component } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom'

import VMLayout from './components/VMLayout'
import VMAuth   from './components/VMAuth/auth.js'

import { Provider } from 'react-redux'
import rootReducer from './reducers'
import { createStore,applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import { Switch, Route } from 'react-router-dom'

/*
const initialState = {
        initCompleted: false,
        initdata: {
            "projects": [],
            "tags": [],
            "user": [],
            "status": []
        }
    }
*/

//Initial state is empty
const store = createStore(rootReducer,applyMiddleware(thunk))

class App extends Component {
  render() {
    return (
      <Provider store={store}>
          <BrowserRouter>
             <div className="App">
             
                <Switch>
                   <Route exact path='/auth' component={VMAuth}/>
                   
                   {/* NO OTHER MATCH */}
                   <Route component={VMLayout} />  
                </Switch> 
             
             </div>
          </BrowserRouter>
      </ Provider>
    );
  }
}

export default App;
