import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import Form from "react-jsonschema-form";
import { Row, Col, message, Modal } from 'antd';
import {schema, uiSchema, validate,onSubmit} from '../../common/formSchema.js'

import './style.css'

/*Insert state from reducers into props*/
const mapStateToProps = ( state, ownProps ) => {
       return {
          initCompleted : state.initRed.initCompleted,
          initdata : state.initRed.initdata
       }
}

const mapDispatchToProps = ( dispatch, ownProps ) => ( {})
   
class VMNewProd extends React.Component {

    constructor( props ) {
        super( props );

        this.onSubmitLocal = this.onSubmitLocal.bind(this);
        
        this.state = {
                famKey : this.props.initdata.families.map(element => element.famKey),
                famVal : this.props.initdata.families.map(element => element.famDesc),
                statKey: this.props.initdata.status.map(element => element.staKey) ,
                statVal: this.props.initdata.status.map(element => element.staDesc),
        }
    };
    
    
    
    onSubmitLocal(formData){
        let history = this.props.history
        onSubmit(formData, history)
    }
    
    
    render() {
    	
        
        const formData={ }
              
        let schemaResolved = {
                ...schema,
                properties : {
                    ...schema.properties,
                    prodFamKey: {
                        ...schema.properties.prodFamKey,
                        "enum" : this.state.famKey,
                        "enumNames": this.state.famVal
                    },
                    
                    prodStaKey: {
                        ...schema.properties.prodStaKey,
                        "enum" : this.state.statKey,
                        "enumNames" : this.state.statVal, 
                    }
                    
                }
        }
        
        return (
                <div>
                
                  <div>
                    <Form schema={schemaResolved} formData={formData} uiSchema={uiSchema} onSubmit={this.onSubmitLocal} validate={validate}  liveValidate />
                  </div>
                  
                <br />
                <br />
                
                <h3>Anteprima immagini</h3>
                
                <div className="vm-carousel">
                   {this.state.formData!==undefined && this.state.formData.main_image_url!==undefined && (
                      <img src={this.state.formData.main_image_url} />   
                      )
                   }
                   
                   {this.state.formData!==undefined && this.state.formData.other_image_url!== undefined && this.state.formData.other_image_url.map( e => {
                       return (<img src={e} /> )
                   })
                   }
                </div>
                
                </div>
                  
                  
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(VMNewProd))