import {Modal} from 'antd';

    const schema = {
                title: "Inserimento nuovo prodotto",
                type: "object",
                
                /*required: [""],*/
                
                definitions: {
                    typeCondition: {
                        anyOf: [
                                  {
                                      "type": "string",
                                      "title": "Nuovo"
                                  },
                                  {
                                      "type": "string",
                                      "title": "Ricondizionato"
                                  },
                                  {
                                      "type": "string",
                                      "title": "Usato"
                                  },
                               ]
                    }
                },

                properties: {
                  prodKey:                      {title: "01. Chiave Prodotto",        type: "integer"  },
                  prodSupplier:                 {title: "02. Azienda Fornitrice",     type: "string", default:"Q", "enum": ["P","Q", "R", "S" ],"enumNames": ["[P] - Sirmec","[Q] - Automeccanica Lucana ", "[R] - Commerciale Lucana Lamiere e Paraurti", "[S] - Commerciale Lucana Cristalli"]},
                  prodPrecode:                  {title: "03. Precodice del prodotto - Esempio - 100 Molle Monroe - 510 Filtri Champions, ecc", type: "string", default: " " },
                  prodId:                       {title: "04. Codice del prodtto ",    type: "string" },
                  prodAltId:                    {title: "05. Codice Alternativo [opzionale]",    type: "string" },
                  prodExternalIdType:           {title: "06. Tipo di codice univoco che si vuole utilizzare",   type: "string", default:"EAN", enum: ["", "EAN", "ASIN", "UPC" ], enumNames: ["", "EAN", "ASIN", "UPC"] },
                  prodExternalId:               {title: "07. Valore del codice univoco", type: "string", default: ""},
                  brand_name:                   {title: "08. Marchio",                type: "string", default: ""},
                  item_name:                    {title: "09. Nome Prodotto (TITOLO)", type: "string", default: ""},
                  manufacturer:                 {title: "10. Produttore",             type: "string", default: ""},
                  prodFamKey:                   {title: "11. Famiglia del prodotto",  type: "number", enum: null, enumNames:null },  //TO RESOLVE                  
                  number_of_items:              {title: "12. Numeri di articoli inclusi nel prodotto. Per esempio, se si tratta di un kit indicare il numero di componenti", type: "integer", default:1, },
                  condition_type:               {title: "13. Condizioni dell'oggetto", type:"string", "enum": ["Nuovo", "Ricondizionato", "Usato" ],"enumNames": ["Nuovo", "Ricondizionato", "Usato"]  },
                  main_image_url :              {title: "14. Immagine Principale" , type: "string", format: "uri"},
                  other_image_url:              {title: "15. Altre Immagini", type: "array", "items": { "type": "string", format: "uri" } },
                  product_description:          {title: "16. Descrizione del prodotto", type:"string" },
                  bullet_point :                {title: "17. Punti chiave del prodotto", type: "array", "items": { "type": "string" }},
                  generic_keywords:             {title: "18. Parole chiavi per la ricerca", type: "array", "items": { "type": "string" }},
                  vehicle_fitment_code:         {title: "19. Codice Compatibilità TecDoc dei veicoli interessati", type: "array", "items": { "type": "string" }},
                  vehicle_fitment_note:         {title: "20. Note sulla compatibilità del veicolo", type:"string"},
                  max_aggregate_ship_quantity:  {title: "21. OFFERTA - Numero massimo di articoli spedibile nello stesso pacco", type:"number"},
                  standard_price:               {title: "22. OFFERTA - Prezzo di acquisto da nostro fornitore, max due decimali - Usa il punto come separatore", type: "string", default: "" },
                  sale_price:                   {title: "23. OFFERTA - Prezzo di acquisto scontato da nostro fornitore (nel caso in cui ci sia un'offerta) - Usa il punto come separatore", type: "string", default: ""},
                  sale_from_date:               {title: "24. OFFERTA - Data inizio offerta", type:"string", format:"date" },
                  sale_end_date:                {title: "25. OFFERTA - Data fine offerta", type:"string", format:"date" },
                  prodStaKey:                   {title: "26. Stato", type: "integer",  "enum": null, "enumNames": null  }, //TO RESOLVE
                  revisioneNote:                {title: "27. Note di revisione", type: "array", "items": { type: "string", default:""} },
                }        
    };
        
    const uiSchema = {
            "prodKey" : { "ui:readonly": true },
            "product_description": {"ui:widget": "textarea", classNames: "productDescription"},
            "sale_from_date": {
                "ui:widget": "alt-date",
                "ui:options": {
                  "yearsRange": [
                    2019,
                    2020
                  ]
                }
            },
            "sale_end_date": {
                "ui:widget": "alt-date",
                "ui:options": {
                  "yearsRange": [
                    2019,
                    2020
                  ]
                }
            }
    }
    
    const validate = function(formData, errors) {
        
        
        if (formData.prodSupplier === undefined || formData.prodSupplier === '' ) {
           errors.prodSupplier.addError("Azienda Fornitrice non valida");
        }
        
        if (formData.prodId === undefined || formData.prodId === '' ) {
            errors.prodId.addError("Codice prodotto non valido");
        }
        if (formData.prodFamKey === undefined || formData.prodFamKey === '' ) {
            errors.prodFamKey.addError("Famiglia del prodotto non valida");
        }
        
        if(formData.standard_price !== undefined && formData.standard_price !== '' ){
            if(isNaN(formData.standard_price)){
                errors.standard_price.addError("Numero non valido. Usa il punto come separatore");
            }
        }
        
        if(formData.sale_price !== undefined && formData.sale_price !== '' ){
            if(isNaN(formData.sale_price)){
                errors.sale_price.addError("Numero non valido.  Usa il punto come separatore");
            }
        }
        
        if(formData.item_name !== undefined && formData.item_name !== '' ){
            if(formData.item_name.length > 500){
               errors.item_name.addError("Campo troppo lungo");
            }
        }
        
        return errors;
     }
    
    
    /*
     * Pass history to redirect to detail page after creting new products
     */
    const onSubmit = function(formData, history) {
        console.log(formData)
        
        fetch( process.env.REACT_APP_API_ENDPOINT + '/prod' ,
                {
                   headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                   },
                   credentials: 'include',
                   method: "POST",
                   body: JSON.stringify(formData)
                })
                .then(response => {
                    if(response.status!==200)
                    {
                       throw new Error(response.status)
                    }
                    return response
                  })
                .then(data=> data.json())
                .then(data=> {
                    console.log("Recevide response from server:")
                    console.log(data)
                    return data
                })
                .then(data => {
                    if(data.error===true){
                        throw new Error(data.message)
                    }else{
                        Modal.success({
                            title: 'Saved',
                            content: 'Your modification has been saved',
                            onOk: function(){
                                console.log("Confirm Modification Popup is closed")
                                
                                if(history!==undefined){
                                    console.log('Redirect to edit mode')
                                    history.push('/detail/' + data.data.prodKey)
                                }
                                
                            }
                          });
                    }
                })
                .catch(err => {
                    
                    let errMessage = err !== null ? err.message : 'Errore non definito';
                    
                    Modal.error({
                        title: 'Error inserting data',
                        content: errMessage,
                        okText: 'OK',
                      });
                })
    }
    
export { schema, uiSchema, validate, onSubmit}
        