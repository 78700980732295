const initialState = {
        filters : []
}

const filtersRed = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'GET_FILTERS':
            
              var newstate = {
              ...state,
              filters : action.data.filters
             }
            return newstate             

        default:
            console.log("filters reducers - default")
            return state
    }
}
export default filtersRed