import React, { Component } from 'react';
import {Layout, Menu, Icon, message} from 'antd';
import { Switch, Route } from 'react-router-dom'
import { Redirect , withRouter } from 'react-router';
import VMHome from '../VMHome';
import VMNewProd from '../VMNewProd';
import VMDetProd from '../VMDetProd';
import './style.css'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import {initAction} from '../../actions/action.js'
import VMPub from '../VMPub/pub.js'
import VMFilter from '../VMFilter/index.js'

const { Header, Content, Sider,Footer } = Layout;


/*

mapStateToProps -> it pass the state of redux to props. Prefix reducers name! It need to access to redux store
mapDispatchToProps -> it pass in the props the actions to dispatch defined inner the function

Workflow:
1. components dispatch Action
2. action returns a new state
3. mapStateToProps function update props
4. View are update from props

*/
/*Insert the state from reducers into props*/
const mapStateToProps = ( state, ownProps ) => {
       return {
          initCompleted : state.initRed.initCompleted,
          initdata : state.initRed.initdata,
       }
}
   
/*Insert dispatch function into props*/
const mapDispatchToProps = ( dispatch, ownProps ) => ( {

   dispatchInitAction: (data) => {
        console.log( "Dispatch Init Action..." )
        console.log( "Data is: "  )
        console.log( data  )
        dispatch(initAction(data) )
    }
})
    
class VMLayout extends Component {

    constructor( props ) {
        super( props );
        
        //Bind this to fetchInitialData
        this.fetchInitialData = this.fetchInitialData.bind(this);

        console.log("AMLayout constuctor props")
        console.log(props)
        
        this.state = {
            collapsed: false,
            initCompleted: props.initCompleted,
            redirectToAuth: false
        };
    }
    
    fetchInitialData = () => {
        console.log("Fetch Initial Data...")
        let these = this;
        fetch( process.env.REACT_APP_API_ENDPOINT + '/init',{
            credentials: 'include'
        })
            .then(data => {
                if (data.status === 401) {                    
                    throw new Error("NOAUTH")
                }else{
                    return data;
                }
            })
            .then( data => data.json() )
            .then( json => {
                var data = {
                    initCompleted: true,
                    initdata: json.data,
                }
                this.props.dispatchInitAction(data)
            })
            .catch(err => {
                if(err.message==='NOAUTH'){
                    these.setState({
                        ...this.state,
                        redirectToAuth : true,
                        initCompleted : false
                    })
                }else{
                    message.error('Error contacting server', 10);
                }
            })
    }
    
    onCollapse = ( collapsed ) => {
        console.log( collapsed );
        this.setState( { collapsed } );
    }
    
    componentDidMount(){
        console.log("AMLayout - Component did mount...")
        this.fetchInitialData()
    }
    
    
    render() {
        
        if(this.state.redirectToAuth){
            return <Redirect to='/auth' />
        }
        
        if(this.props.initCompleted===false){
            console.log("Init not completed")
            return(<div><p>LOADING...</p></div>);
        }
        console.log("Init completed")
        
        return (
                <Layout style={{ minHeight: '100vh' }}>
                <Sider
                  collapsible
                  collapsed={this.state.collapsed}
                  onCollapse={this.onCollapse}
                >
                  <div className="logo" />
                  
                  <div className="userName">
                     <span>{this.props.initdata.currentUser.uName}</span>
                  </div>    
                  
                  <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                  
                    <Menu.Item key="1">
                      <Link to='/home'>
                      <Icon type="pie-chart" />
                      <span>Products</span>
                      </Link>
                    </Menu.Item>
                    
                    <Menu.Item key="2" >
                      <Link to='/new'>
                      <Icon type="desktop" />
                      <span>New Product</span>
                      </Link>
                    </Menu.Item>
                    
                    <Menu.Item key="3" >
                      <Link to='/filter'>
                      <Icon type="desktop" />
                      <span>Filter</span>
                      </Link>
                    </Menu.Item>
                    
                    {this.props.initdata.currentUser && this.props.initdata.currentUser.uRole==='ADMIN' && (
                    <Menu.Item key="4" >
                      <Link to='/publish'>
                      <Icon type="desktop" />
                      <span>Export</span>
                      </Link>
                    </Menu.Item>
                    )
                    }
                    <Menu.Item key="5" >
                      <a href={process.env.REACT_APP_USERGUIDE_LINK} target="_blank">
                      <Icon type="book" />
                      <span>User Guide</span>
                      </a>
                    </Menu.Item>
                    
                  </Menu>
                  
                </Sider>
                <Layout>
                  <Header style={{ background: '#fff', padding: 0 }}> <h2>Virtual Marketplace</h2> </Header>
                  <Content style={{ margin: '0 16px' }}>
                  
                     <Switch>
                         <Route exact path='/' component={VMHome}/>
                         <Route path='/home' component={VMHome}/>
                         <Route path='/new' component={VMNewProd}/>
                         <Route path='/detail' component={VMDetProd}/>
                         <Route path='/publish' component={VMPub}/>
                         <Route path='/filter' component={VMFilter}/>
                     </Switch> 
                  </Content>
                  <Footer style={{ textAlign: 'center' }}>
                    Virtual Marketplace ©2018  Version: {process.env.REACT_APP_VERSION}
                  </Footer>
                </Layout>
              </Layout>
        )
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(VMLayout))