export const initAction = (data) => ( {
        type: 'INIT_APP',
        data: data,
} )

export const loadProducts = (data) => ( {
        type: 'LOAD_PROD',
        data: data,
} )

export const updateActivity = (data) => ( {
        type: 'UPD_ACT',
        data: data,
} )


export const setFilters = (data) => ( {
        type: 'GET_FILTERS',
        data: data,
} )
