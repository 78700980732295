import React from 'react';
import { withRouter } from 'react-router';
import {manageResp, manageError} from '../../common.js'
import {updateActivity} from '../../actions/action.js'
import { connect } from 'react-redux'

import {
    Form, Input, Select, Button,DatePicker, message, Tabs 
} from 'antd';

import moment from 'moment';
import './style.css' 

const { Option } = Select;
const TabPane = Tabs.TabPane;

class VMProd extends React.Component {

    constructor( props ) {
        super( props );

        this.handleProjChange= this.handleProjChange.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.handleTagsChange=this.handleTagsChange.bind(this);
        this.handleTitleChange=this.handleTitleChange.bind(this);
        this.handleDescriptionChange=this.handleDescriptionChange.bind(this);
        this.handleStatusChange=this.handleStatusChange.bind(this);
        this.handleOwnerChange=this.handleOwnerChange.bind(this);
        this.handleHandleAddLog=this.handleHandleAddLog.bind(this);
        this.handleChangeDueDate=this.handleChangeDueDate.bind(this);
        this.handleChangeLog=this.handleChangeLog.bind(this);
        this.handleUpdateLog=this.handleUpdateLog.bind(this);
        this.handleLogChange=this.handleLogChange.bind(this);
        this.handleEstimateEffort=this.handleEstimateEffort.bind(this);
        this.handleAddWorklog = this.handleAddWorklog.bind(this);
    }
     
    handleProjChange(value,options){       
        this.props.act.projId = options.props.value        
        //console.log("New state:")
        //console.log(this.props.act)
    }
    
    handleTagsChange(value,options){
        //console.log(options)
        //this.props.act.tags =
        
        var values = []
        
        options.reduce(function(total, currentValue, currentIndex, arr){
            var t = {
                    tagId: currentValue.props.value,
                    tagDesc : ""
            }
            return values.push(t) 
        }, values)
        
        this.props.act.tags= values
    }
    
    handleOwnerChange(value,options){
        //console.log(options)
        //this.props.act.tags =
        
        var values = []
        
        options.reduce(function(total, currentValue, currentIndex, arr){
            
            var u = {
                    uid: currentValue.props.value,
            }
            return values.push(u) 
        }, values)
        
        this.props.act.owners = values
    }
    
    
    handleTitleChange(e){
        this.props.act.actTitle = e.target.value
    }
    
    handleDescriptionChange(e){
        this.props.act.actDesc = e.target.value
    }
    
    handleStatusChange(value,options){
        this.props.act.staId = options.props.value
    }
    
    
    handleChangeDueDate(date, dateString){
        this.props.act.actDueDate = dateString
    }
    
    handleChangeLog(event){
        this.props.act.actNewLog = event.target.value
    }
    
    handleEstimateEffort(event){
        this.props.act.actEstEff = event.target.value
    }
    
    
    handleLogChange(e, logId){
        this.props.act.logs.map( function(l) {
            if(l.logId===logId){
                l.logText = e.target.value
            }
        })
    }
    
    render() {
        //const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 2 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 2,
                },
            },
        };
        
 
        
         /*
          * Display an activity based on initdata and activity data
         */   
        
        
        let selectedOwners = "" 
        if(this.props.act!==undefined){
            selectedOwners = this.props.act.owners.map(value => value.uid);
        }
        
        let selectedTags = []
        if(this.props.act!==undefined && this.props.act.tags!==undefined && this.props.act.tags!==null){
            selectedTags = this.props.act.tags.map(value => value.tagId);
        }
        
        if(this.props.act==null){
            return null;
        }
        
        let insertDate = moment();
        if(this.props.act.actInsDate!==undefined && this.props.act.actInsDate!==''){
            insertDate = moment(this.props.act.actInsDate)
        }
        
        let dueDate = null;
        if(this.props.act.actDueDate!==undefined && this.props.act.actDueDate!== null && this.props.act.actDueDate!==''){
            console.log("dueDate:" + this.props.act.actDueDate)
            dueDate = moment(this.props.act.actDueDate)
        }
        
        return (
            <div>
            <Form onSubmit={this.handleSubmit}>
                <Form.Item
                    {...formItemLayout}
                    label="Project"
                >
                    <Select
                       showSearch
                       size="default"
                       style={{ width: '100%' }}
                       defaultValue={this.props.act.projId}
                       onChange={this.handleProjChange}
                       filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                     >
                       {
                           this.props.initdata.projects.map(function(el, index){
                              return <Option key="el.projDesc" value={el.projId}>{el.projDesc}</Option>;
                           })
                       }
                    </Select>
                 </Form.Item>
                 
                  <Form.Item
                    {...formItemLayout}
                    label="Tags"
                   >   
                    <Select
                       mode="multiple"
                       size="default"
                       style={{ width: '100%' }}
                       defaultValue={selectedTags}
                       onChange={this.handleTagsChange}
                       filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                     >
                       {
                           this.props.initdata.tags.map(function(el, index){
                               return <Option key="el.tagDesc" value={el.tagId}>{el.tagDesc}</Option>;
                           })
                       }
               
                    </Select>
                    </Form.Item>

                    <Form.Item
                    {...formItemLayout}
                    label="Title">
                       <Input onChange={this.handleTitleChange} placeholder="Title of activity" defaultValue={this.props.act.actTitle} />
                    </Form.Item>
                       
                    <Form.Item
                    {...formItemLayout}
                    label="Description">
                       <Input.TextArea onChange={this.handleDescriptionChange} rows={4} placeholder="Description of activity" defaultValue={this.props.act.actDesc} />
                    </Form.Item>   
                    
                    
                    <Form.Item
                    {...formItemLayout}
                    label="Owner"
                   >   
                    <Select
                       mode="multiple"
                       size="default"
                       style={{ width: '100%' }}
                       defaultValue={selectedOwners}
                       onChange={this.handleOwnerChange}
                       filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                     >  
                         {
                           this.props.initdata.user.map(function(el, index){
                               return <Option key="el.tagId" value={el.uid}>{el.uName}</Option>;
                           })
                         }
                    </Select>
                    </Form.Item>

                    <Form.Item
                    {...formItemLayout}
                    label="Insert Date">
                       <DatePicker className="datepicker" defaultValue={insertDate} disabled />
                    </Form.Item>
    
                    <Form.Item
                    {...formItemLayout}
                    label="Due Date">
                       <DatePicker className="datepicker" onChange={this.handleChangeDueDate} defaultValue={dueDate} />
                    </Form.Item>   

                    <Form.Item
                    {...formItemLayout}
                    label="Estimate Effort">
                       <Input onChange={this.handleEstimateEffort} placeholder="Estimated effort in hours" defaultValue={this.props.act.actEstEff} />
                    </Form.Item>   
                    
                    
                    <Form.Item
                    {...formItemLayout}
                    label="STATUS">
                    <Select
                       showSearch
                       defaultValue={this.props.act.staId}
                       size="default"
                       style={{ width: '100%' }}
                       onChange={this.handleStatusChange}
                       filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                     >
                        {
                           this.props.initdata.status.map(function(el, index){
                               return <Option key="el.tagId" value={el.staId}>{el.staDesc}</Option>;
                           })
                         }
                    </Select>                    
                    </Form.Item>
                    
                </Form>
                
                
                <Form>    
                    <Form.Item
                    {...tailFormItemLayout}
                    >   
                        <Button onClick={this.handleSubmit} className="sendButton" type="primary">Save</Button>
                    </Form.Item>
                </Form>
                
                
                 <Form>    
                    <Form.Item {...formItemLayout} label="Other" > 
                 <Tabs type="card">
                 
                     {
                         /* LOGS */
                     }
                     <TabPane tab="Activity Logs" key="1">

                           <div key="newlog">
                              <span style={{textAlign:'left'}}><b>Add new log</b></span>
                                    <Form.Item {...formItemLayout}> 
                                        <Input.TextArea onChange={this.handleChangeLog} defaultValue="" rows={4} /> 
                                        <Button onClick={this.handleSubmit} className="editLogButton" type="primary">Save and add Log</Button>
                                    </Form.Item>
                            </div>
                           {
                             (this.props.act.logs || [] ).map(function(el, index){
                                 
                             let readOnlyFlag = (this.props.initdata.uid === el.logUid  ? false : true)    
                             return(
                                <div key={el.logId}>
                                    <span style={{textAlign:'left'}}><b>{el.user.uName}</b> - <i>{el.logInsInst}</i></span>
                                    <Form.Item {...formItemLayout}> <Input.TextArea onChange={(e)=>this.handleLogChange(e,el.logId)} defaultValue={el.logText} rows={4} readOnly={readOnlyFlag} /> 
                                       <Button  onClick={(e) => this.handleUpdateLog(e, el.logId)} className="editLogButton" type="primary">Update</Button>
                                    </Form.Item>
                                </div>
                             )
                             }, this)
                           }
   
                             
                     </TabPane>
                     
                     {
                         /* WORKLOG */
                     }
                     <TabPane tab="Effort" key="2">
                     
                         <div key="new">
                             <Form.Item layout="inline" {...formItemLayout}> 
                                <Input style={{width:'65%'}} placeholder="description" /> 
                                <Input style={{width:'35%'}} type="number" placeholder="hours" />
                                <Button className="editLogButton" type="primary" onClick={this.handleAddWorklog}>Update</Button>
                             </Form.Item>
                          </div>
                          
                          {
                             (this.props.act.worklogs || [] ).map(function(el, index){
                                        
                                let readOnlyFlag = (this.props.initdata.uid === el.uid  ? false : true)    
                                return(
                                   <div key={el.wlogId}>
                                      <span style={{textAlign:'left'}}><b></b> - <i></i></span>
                                         <Form.Item {...formItemLayout}> 
                                            
                                            <Input style={{width:'65%'}} defaultValue={el.wlogDesc} placeholder="description" /> 
                                            <Input style={{width:'35%'}} defaultValue={el.hours} type="number" placeholder="hours" />
                                            <Button className="editLogButton" type="primary" onClick={this.handleAddWorklog}>Update</Button>
                                         </Form.Item>
                                    </div>
                                 )
                              }, this)
                          }
                     </TabPane>
                        
                     
                     <TabPane tab="History" key="3">History</TabPane>
                 </Tabs>
                 
                    </Form.Item>
                   </Form>
                
                </div>
        );
    }
}

let f = Form.create({})(VMProd)
//export default withRouter(connect(mapStateToProps,mapDispatchToProps)(f))
export default withRouter(f)
