import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import Form from "react-jsonschema-form";
import { Row, Col, message, Modal } from 'antd';
import { schema, uiSchema, validate, onSubmit } from '../../common/formSchema.js'
import { setFilters } from '../../actions/action.js'
import { fetchFilter } from '../../fetch/filters.js'


import './style.css'


/*Insert the state from reducers into props*/
const mapStateToProps = ( state, ownProps ) => {
    return {
        filters: state.filtersRed.filters,
    }
}

///*Insert dispatch function into props*/
//const mapDispatchToProps = ( dispatch, ownProps ) => ( {
//
//   dispatchInitAction: (data) => {
//        console.log( "Dispatch Init Action..." )
//        console.log( "Data is: "  )
//        console.log( data  )
//        dispatch(initAction(data) )
//    }
//})


class VMFilter extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            filters: this.props.filters.map( v => v.filString)
        }
        
        this.submit = this.submit.bind(this)
    };

    componentDidUpdate( prevProps ) {
        if ( prevProps.filters !== this.props.filters ) {

            this.setState( {
                filters: this.props.filters.map( v => v.filString)
            } )
        }
    }


    submit( formData ) {
        
        console.log(formData)
        
        let fil = formData.formData.filters //formData.formData.filters.map( currentValue => currentValue.filString )

        var params = new URLSearchParams();

        for ( var i = 0; i < fil.length; i++ ) {
            params.append( "filterString", fil[i] )
        }

        fetch( process.env.REACT_APP_API_ENDPOINT + '/filter',
            {
                headers: {
                    'Accept': 'application/json',
                },
                credentials: 'include',
                method: "POST",
                body: params
            } ).then( () => {
              
                this.setState({
                    ...this.state,
                    filters : fil
                })
                
                
            })
            .catch(err => {
                    
                    let errMessage = err !== null ? err.message : 'Errore non definito';
                    
                    Modal.error({
                        title: 'Error saving filter',
                        content: errMessage,
                        okText: 'OK',
                      });
                })
    }

    componentDidMount() {

        let these = this;

        fetch( process.env.REACT_APP_API_ENDPOINT + '/filter',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                method: "GET",
            } )
            .then( response => {
                if ( response.status !== 200 ) {
                    throw new Error( response.status )
                }
                return response
            } )
            .then( data => data.json() )
            .then( data => {
                console.log( "Recevide response from server:" )
                console.log( data )
                return data
            } )
            .then( data => {
                if ( data.error === true ) {
                    throw new Error( data.message )
                } else {
                    let ris = {
                        ...this.state,
                        filters: data.data
                    }
                    these.props.dispatch( setFilters( ris ) )
                }
            } )
            .catch( err => {
                let errMessage = err !== null ? err.message : 'Errore non definito';
                
                Modal.error({
                    title: 'Error fetching filter',
                    content: errMessage,
                    okText: 'OK',
                  });
                
            } )
    }


    render() {

        let fil = this.state.filters.map(currentValue=>currentValue)
        
        const formData = {
                filters: fil
        }

        const schema = {
            title: "Inserisci i filtri",
            type: "object",

            properties: {
                filters: { title: "Filtri", type: "array", "items": { type: "string", default: "" } },
            }
        };

        const uiSchema = {

        }



        return (
            <div>
                <Form schema={schema} formData={formData} uiSchema={uiSchema} onSubmit={this.submit} /*validate={}*/ liveValidate />
            </div>


        )
    }
}

export default withRouter( connect( mapStateToProps )( VMFilter ))