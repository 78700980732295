import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux'
import {message, Modal } from 'antd';

import moment from 'moment';
import './style.css' 
import Form from "react-jsonschema-form";

/*Insert state from reducers into props*/
const mapStateToProps = ( state, ownProps ) => {
       return {
          initCompleted : state.initRed.initCompleted,
          initdata : state.initRed.initdata
       }
}

const mapDispatchToProps = ( dispatch, ownProps ) => ( {})
class VMPub extends React.Component {

    constructor( props ) {
        super( props );
        
        this.state = {
                famKey : this.props.initdata.families.map(element => element.famKey),
                famVal : this.props.initdata.families.map(element => element.famDesc),
                statKey: this.props.initdata.status.map(element => element.staKey) ,
                statVal: this.props.initdata.status.map(element => element.staDesc),
        }
    }

    validate(formData, errors) {

        if(formData.priceMult !== undefined && formData.priceMult !== '' ){
            if(isNaN(formData.priceMult)){
                errors.priceMult.addError("Numero non valido. Usa il punto come separatore");
            }
        }
        
        if(formData.prodKeyTo !== undefined && formData.prodKeyTo !== '' ){
            if(formData.prodKeyTo < formData.prodKeyFrom ){
                errors.prodKeyTo.addError("Chiave prodotto to deve essere maggiore o uguale a chiave prodotto from");
            }
        }
        
        
        return errors;
     }
    
    
    onSubmit(formData){
        
        let keys = []
        
        let start =0;
        let end   =-1;
        
        if(!isNaN(formData.formData.prodKeyFrom)){
            start=formData.formData.prodKeyFrom
        }
        
        if(!isNaN(formData.formData.prodKeyTo)){
            end=formData.formData.prodKeyTo
        }
        
        if(start<=end){
            
            for(var i=start;i<=end;i++){
                keys.push(i)
            }
        }
        
        
        let body = {
                channels: ["AMAZON"],
                prodKeys: keys,
                status : formData.formData.prodStaKey,
                priceMult : formData.formData.priceMult
        }
        
        console.log("START KEY:" + start)
        console.log("END KEY:"   + end)
        console.log("GEN KEYS:"  + keys)
        console.log("STATUS:"   + formData.formData.prodStaKey)
        console.log("PRICE MULT:"   + formData.formData.priceMult)
        
        fetch( process.env.REACT_APP_API_ENDPOINT + '/pub/export' ,
                {
                   headers: {
                      'Accept': '*/*',
                      'Content-Type': 'application/json'
                   },
                   credentials: 'include',
                   method: "POST",
                   body: JSON.stringify(body)
                })
                .then(response => {
                    if(response.status!==200)
                    {
                       throw new Error(response.status)
                    }
                    return response
                  })
                 .then(response => response.blob())
                 .then(blob => {
                     var url = window.URL.createObjectURL(blob);
                     var a = document.createElement('a');
                     a.href = url;
                     a.download = "vm-export.txt";
                     document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                     a.click();    
                     a.remove();  //afterwards we remove the element again         
                  })
                .catch(err => {
                    
                    let errMessage = err !== null ? err.message : 'Errore non definito';
                    
                    Modal.error({
                        title: 'Error inserting data',
                        content: errMessage,
                        okText: 'OK',
                      });
                })
    }
    

    render() {
        
        const schema = {
                title: "Select Product To Publish",
                type: "object",
                
                required: ["priceMult"],
                
                properties: {
                  prodKeyFrom:                      {title: "Chiave Prodotto From",    type: "integer"  },
                  prodKeyTo:                        {title: "Chiave Prodotto To",      type: "integer"    },
                  prodStaKey:                       {title: "Stato",                   type: "array", "items": { type: "string" , enum: [], enumNames: [] }, "uniqueItems": true },
                  channel:                          {title: "Canale",                  type: "string",  "enum": ["AMAZON"], "enumNames": ["AMAZON"], "default":"AMAZON" },
                  priceMult:                        {title: "Moltiplicatore Prezzo",   type: "string",  "default":"1.83" },
                }        
        };
        
        let schemaResolved = {
                ...schema,
                properties : {
                    ...schema.properties,
                    prodStaKey: {
                        ...schema.properties.prodStaKey,
                        items : {
                        "enum" : this.state.statKey,
                        "enumNames" : this.state.statVal,
                        }
                    }
                }
        }
        
        const uiSchema = {
                prodStaKey: {
                    "ui:widget": "checkboxes"
                  },
        }
    
        const formData={ }
        
        
        return (
           <div>
              <Form schema={schemaResolved} formData={formData} uiSchema={uiSchema} onSubmit={this.onSubmit} validate={this.validate} liveValidate />
           </div>
           
        );
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(VMPub))
