var colorsTag = {}

colorsTag = {
        1 : "#966B9D",
        2 : "#EEC584",
        3 : "#90A583",
        4 : "#759FBC",
        5 : "#E7CFBC",
        6 : "#C98686",
        7 : "#D9594C",
        8 : "#CE8D66",
        9 : "#DDFFD9",
        10: "#D7907B",
        11: "#6C4B5E",
        12: "#B3679B",
        13: "#561D25",
        14: "#CE8147",
        15: "#ECDD7B",
        16: "#D3E298",
        17: "#CDE7BE",
        18: "#94B9AF",
        19: "#942911",
        20: "#7F7979"
        
}


var userTag = {}
userTag = {
   1: "#8FD694",    
   2: "#95ADB6",
   3: "#CBB3BF",
   4: "#DBC7BE",
   5: "#EF959C",
   6: "#7AC74F",
   7: "#CE8147",
   8: "#D5D887",
   9: "#E0C879",
   10:"#1F5673",
   11:"#6E0D25",
   12:"#FFFFB3",
   13:"#DCAB6B",
   14:"#774E24",
   15:"#774E24",
   16:"#6A381F"
}

var statusTag = {}
statusTag = {
   1: "#DB2B39",    
   2: "#29335C",
   3: "#F3A712",
   4: "#F0CEA0",
   5: "#534D41",
   6: "#7AE7C7",
   7: "#75BBA7",
   8: "#6C809A",
   9: "#795663",
   10:"#645244"
}



export { colorsTag, userTag, statusTag }