import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import VMProd from '../VMProd/prod.js'

import {Modal} from 'antd';
import {schema, uiSchema, validate,onSubmit} from '../../common/formSchema.js'
import Form from "react-jsonschema-form";
import { Carousel } from 'antd';
import './style.css'

/*Insert state from reducers into props*/
const mapStateToProps = ( state, ownProps ) => {
       return {
          initCompleted : state.initRed.initCompleted,
          initdata : state.initRed.initdata,
          activities : state.activitiesRed
       }
}

const mapDispatchToProps = ( dispatch, ownProps ) => ( {})
   
class VMDetProd extends React.Component {

    constructor( props ) {
        super( props );
        
        this.onSubmitLocal = this.onSubmitLocal.bind(this);
        
        
        this.state = {
                famKey : this.props.initdata.families.map(element => element.famKey),
                famVal : this.props.initdata.families.map(element => element.famDesc),
                statKey: this.props.initdata.status.map(element => element.staKey) ,
                statVal: this.props.initdata.status.map(element => element.staDesc),
                formData : {}
        }
    }
    
    componentDidMount() {
        
        let url = window.location.href
        let parts = url.split('/')
        let prodKey = parts[parts.length - 1]
        
        console.log("Product key: " + prodKey)
        
        fetch( process.env.REACT_APP_API_ENDPOINT + '/prod/' + prodKey, {
            credentials: 'include'
        }) 
        .then(response => {
            if(response.status!==200)
            {
               throw new Error(response.status)
            }
            return response
          })
        .then(data=> data.json())
        .then(data=> {
            console.log("Recevide response from server:")
            console.log(data)
            return data
        })
        .then(data => {
            if(data.error===true){
                throw new Error(data.message)
            }
            
            if(data.data.details!==undefined && data.data.details.formData !== undefined){
                this.setState({
                   ...this.state,
                   formData : data.data.details.formData
                });
            }else{
                throw new Error("No details found")
            }
            
        })
        .catch(err => {
            
            let errMessage = err !== null ? err.message : 'Errore non definito';
            
            Modal.error({
                title: 'Error reading post details',
                content: errMessage,
                okText: 'OK',
              });
        })
    }
    
    onSubmitLocal(formData){
        let history = undefined
        onSubmit(formData, history)
    }
    
    render() {
        
        let schemaResolved = {
                ...schema,
                
                properties : {
                    ...schema.properties,
                    prodFamKey: {
                        ...schema.properties.prodFamKey,
                        "enum" : this.state.famKey,
                        "enumNames": this.state.famVal
                    },
                    prodStaKey: {
                        ...schema.properties.prodStaKey,
                        "enum" : this.state.statKey,
                        "enumNames" : this.state.statVal, 
                    }
                }
        }
        
        return (
                
                <div>
                    <div>
                      <Form schema={schemaResolved} formData={this.state.formData} uiSchema={uiSchema} onSubmit={this.onSubmitLocal} validate={validate}  liveValidate />
                    </div>
                    
                    <br />
                    <br />
                    
                    <h3>Anteprima immagini</h3>
                    
                    <div className="vm-carousel">
                        {this.state.formData!==undefined && this.state.formData.main_image_url!==undefined && (
                           <img src={this.state.formData.main_image_url} />   
                           )
                        }
                        
                        {this.state.formData!==undefined && this.state.formData.other_image_url!== undefined && this.state.formData.other_image_url.map( e => {
                            return (<img src={e} /> )
                        })
                        }
                    </div>
                </div>
        )
        
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(VMDetProd))