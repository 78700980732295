const initialState = {
    initCompleted: false,
    initdata: {
        "projects": [],
        "tags": [],
        "user": [],
        "status": [],
        "uid" : ""
    }
}

const initRed = ( state = initialState, action ) => {
    switch ( action.type ) {
        case 'INIT_APP':
            
            console.log("initial reducers - INIT_APP")
            
            var d = initialState.initdata;
            if(action.data!==undefined){
                d=action.data.initdata
            }
              var newstate = {
              ...state,
              initCompleted: true,
              initdata : d,
             }
            return newstate             

        default:
            console.log("initial reducers - default")
            return state
    }
}
export default initRed